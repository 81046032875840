export default function replaceSearchQueryValues(
	link,
	data,
	valuePattern = '^\\$\\{.*?\\}$'
) {
	if (!link || !data) return link;

	let [path, queryStr] = link.split('?');
	if (!queryStr) return link;

	const queryParam = new URLSearchParams(queryStr);
	const regex = new RegExp(valuePattern);

	queryParam.forEach((value, key) => {
		if (regex.test(value)) {
			if (data[key]) {
				queryParam.set(key, data[key]);
			} else {
				queryParam.delete(key, data[key]);
			}
		}
	});

	return `${path}?${queryParam.toString()}`;
}
