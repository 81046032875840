import get from 'lodash/get';

export const getOrderPayload = (config) => ({
	email: get(config, 'userDetail.email') || null,
	contact: get(config, 'userDetail.mobile') || null,
	coupon_code: config.couponCode || null,
	product_code: config.productCode,
	data: {
		currency: 'INR',
		payment_capture: 1,
		amount: config.productAmount * 100,
		notes: { zoom_id: config.webinarId, zoom_type: config.zoomType },
	},
});

export const getPrefillData = (config) => ({
	email: get(config, 'userDetail.email') || null,
	contact: get(config, 'userDetail.mobile') || null,
});

export const getEventProps = (orderDetails, transactionId) => {
	const item = {
			quantity: 1,
			price: orderDetails.product.amount,
			item_id: orderDetails.product.product_code,
			item_name: orderDetails.product.product_name,
		},
		ecommerce = {
			currency: orderDetails.currency,
			value: orderDetails.amount,
		};

	if (transactionId) ecommerce.transaction_id = transactionId;
	if (get(orderDetails, 'coupon.code')) {
		ecommerce.coupon = orderDetails.coupon.code;
		item.coupon = orderDetails.coupon.code;
		item.discount = orderDetails.coupon.discount || null;
	}

	ecommerce.items = [item];

	return { ecommerce };
};
