export const formTypes = {
	paymentForm: 'paymentForm',
	form: 'form', // regular
};

export const paymentFormDataSource = {
	couponCode: 'couponCode',
	discountAmount: 'discountAmount',
};

export const counsellorFilterForm = {
	desktopUiSchema: {
		type: 'HorizontalLayout',
		elements: [
			{
				type: 'Control',
				scope: '#/properties/city',
				label: 'Location',
				options: {
					component: 'multiSelect',
				},
			},
			{
				type: 'Control',
				scope: '#/properties/target_country',
				label: 'Target Country',
				options: {
					component: 'multiSelect',
				},
			},
		],
	},
	tabletUiSchema: {
		type: 'HorizontalLayout',
		elements: [
			{
				type: 'Control',
				scope: '#/properties/city',
				label: 'Location',
				options: {
					component: 'multiSelect',
				},
			},
			{
				type: 'Control',
				scope: '#/properties/target_country',
				label: 'Target Country',
				options: {
					component: 'multiSelect',
				},
			},
		],
	},
	mobileUiSchema: {
		type: 'VerticalLayout',
		elements: [
			{
				type: 'Control',
				scope: '#/properties/city',
				label: 'Location',
				options: {
					component: 'multiSelect',
				},
			},
			{
				type: 'Control',
				scope: '#/properties/target_country',
				label: 'Target Country',
				options: {
					component: 'multiSelect',
				},
			},
		],
	},
	controlSchema: {
		type: 'object',
		properties: {
			city: {
				type: 'array',
				items: {
					type: 'string',
					enum: [
						'Ahmedabad',
						'Bangalore',
						'Chennai',
						'Delhi',
						'Hyderabad',
						'Jaipur',
						'Kochi',
						'Mumbai',
						'Pune',
						'Surat',
						'Thane',
						'Vadodara',
						'Vijayawada',
					],
				},
			},
			target_country: {
				type: 'array',
				items: {
					type: 'string',
					enum: [
						'Australia',
						'Canada',
						'Dubai',
						'Germany',
						'France',
						'Ireland',
						'Italy',
						'Malaysia',
						'New Zealand',
						'Singapore',
						'Spain',
						'UK',
						'USA',
					],
				},
			},
		},
		required: [],
	},
};
